/**
 * Created by zeitler on 08-06-2016.
 */
$(document).ready(function(){
    $('#symbol').addClass('go');

    var span = $("#header #languageSelector span");
    var ul = $("#header #languageSelector ul");
    drop.register(span, ul)
    span = $("#menu_options #languageSelector span");
    ul = $("#menu_options #languageSelector ul");
    drop.register(span, ul)



    createChangeLanguageEvent("header");
    createChangeLanguageEvent("menu_options");
    $("#overlay").click(function(){
        $("#menu_icon").click();
    })
    removeElementOnClick('messages_overlay');
    $('#site_wrapper').addClass('loaded');
    $('#menu a').click(function (e) {
        on_link(e, this);
    })
    $('#pageHeader h1 a').click(function (e) {
        on_link(e, this);
    })
    $('#presentSub li a').click(function (e) {
        on_link(e, this);
    })

    $('#article_header a').click(function (e) {
        on_link(e, this);
    })
});

function on_link(e, linker){
    // e.preventDefault(/);
    // $('#site_wrapper').removeClass('loaded')
    // setTimeout('window.location.href="' + $(linker).attr('href')+'"', 1000);
}



function createChangeLanguageEvent(id){
    /*
    Expects an div, and a hidden ID
    Creates an event to change the hidden value and submits the form
    The div needs an Ul and a Form
     */
    var form = $("#" + id + " #languageSelector form");
    var hidden = $("#" + id + " #languageSelector form input")[1];
    $("#" + id + " ul li").each(function(){
        $(this).click(function(){
            // console.log($(this).attr("id"))
            hidden.value = $(this).attr("id");
            form.submit();
        });
    })

}


function fb(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.8";
    fjs.parentNode.insertBefore(js, fjs);
}

function removeElementOnClick(id){
    var e = $('#'+id);
    e.click(function(){
        e.fadeOut('slow');
    });
}
